export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Inicio',
        to: '/home',
        icon: 'cil-home',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['secciones']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Usuarios',
        to: '/user',
        icon: 'cilPeople'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Patologías',
        to: '/pathology',
        icon: 'cilApps'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consejos',
        to: '/advice',
        icon: 'cilLightbulb'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Retos',
        to: '/challenge',
        icon: 'cilBadge'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Alertas',
        to: '/alert',
        icon: 'cilBell'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Sesión']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cerrar sesión',
        to: '/pages/login',
        icon: 'cilXCircle',
      },
    ]
  }
]